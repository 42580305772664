.get-matched-modal-container {
  width: calc(100% - 32px);
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.text-color {
  color: var(--gray-700, #344054);
}

.background {
  background-color: var(--gray-200, #eaecf0);
}

input {
  border: 1px solid var(--gray-300, #d0d5dd);
  outline: none;
}

.cap-btn:hover {
  color: white !important;
}



.result-box-modal {
  width: calc(100% - 32px);
  /* height: min-content !important; */
}

.loading-modal-container {
  width: calc(100% - 32px);
  height: 748px;
  border-radius: 12px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .get-matched-modal-container {
    height: min-content !important;
    border-radius: 12px;

  }

  .loading-modal-container {
    height: min-content !important;
  }
}

.text-box {
  position: relative;
  width: 100%;
  height: 25px;
  overflow: hidden;
  /* border: 1px solid #000; */
}

.text {
  line-height: 25px;
  /* text-align: center; */
  /* background-color: red; */
  height: 50px;
  /* transition: transform 1s; */
}

.anime-box {
  width: 100%;
  position: absolute;
  transition: 1s;
}


.animate {
  margin-top: -50px;
}