@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.slider-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;


  background: #EAECF0;
  outline: none;
  /* opacity: 0.7; */
  -webkit-transition: .2s;
  transition: opacity .2s;
  padding: 0 !important;
  border: none !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  border: 2.25px solid #3364F7;
}

.slider::-moz-range-thumb {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;

  border: 3px solid #3364F7;
}

.slider-value {
  position: absolute;
  top: 45px;

  transform: translateX(-50%);
  background-color: white;
  padding: 12px 18px;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

}

.slider-value span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

@media screen and (max-width: 480px) {

  .slider-value {
    top: 75px;
  }
}