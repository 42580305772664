.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-container {
  display: flex;
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 1008px; /* Adjust as per design */
  height: 582px;
  padding: 32px;
  gap: 32px;
}

.left-section {
  background-color: #3364f7; /* Replace with exact color from design */
  padding: 24px;
  color: white;
  width: 50%; /* Adjust as per design */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
}

.left-section .modal-title{
line-height: 28px;
}

.left-section .plan-price{
line-height: 60px;
}

.left-section .plan-name{
line-height: 30px;
}

.left-section .plan-benefits{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 344px;
  row-gap: 11px;
  column-gap: 12px;
  margin-top: 16px;
  padding: 5px;
}



.modal-title, .plan-price, .plan-name, .plan-description {
  margin: 0 0 10px 0; /* Adjust spacing as per design */
}

.plan-benefits li {
  /* Style list items as per design */
}

.right-section {
  width: 50%; /* Adjust as per design */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.right-section .payment-form{
  width: 100%;
}
.right-section .form-group{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.form-group {
  margin-bottom: 15px;
}

.half-width {
  display: inline-block;
  width: 48%; /* Adjust as needed for design */
  margin-right: 4%;
}

.stripe-form-section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stripe-form-section .form-actions {
  display: flex;
  justify-content: flex-end;
}

.cancel-button, .start-button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

.form-group .StripeElement{
  width: 100%;
  height: 16px;
}

.form-group .input{
  height: 44px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  padding: 10px 14px !important;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--base-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
}
/* Add more styles to match the exact design */