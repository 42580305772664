.agency-mobile-card{
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #F9FAFB;
    display: flex;
    max-width: 500px;
    width: 100%;
    height: 270px;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    margin-bottom: 16px;
   
}

.agency-mobile-header{
    height: 190px;
    /* height: 150px; */
    width: 100%;
    padding: 12px 16px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.agency-mobile-head{
    width: calc(100% - 42px);
}

.agency-mobile-options{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 190px);
    /* height: calc(100% - 150px); */
    
}

.agency-mobile-option{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 50%;
    MIN-height: 50%;
    padding: 8px 12px;
    gap: 4px;
    border-top: 1px solid #EAECF0;
}

.agency-mobile-head svg{
    height: 15px;
    width: 15px;
    margin-right: 2px !important;
}

.agency-mobile-head .star-container{
    height: 15px;
    width: 15px;
    margin-right: 2px !important;
}