#customers {
  width: 100%;
}

#customers tr {
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #475467;
  border-top: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
}

#customers .th {
  padding: 12px 24px;
  /* border-bottom: 1px solid #EAECF0 */
}
#customers .th span {
  width: 100%;
  /* -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden; */
}
textarea{
  padding: 0px !important;
  
}

#customers .td {
  padding: 16px 24px;
  word-wrap: break-word;
}

#customers .td span {
  /* width: 100%; */
  /* -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden; */
  /* word-wrap: break-word */
}

#customers tr:nth-child(even) {
  background-color: #f9fafb;
}

#customers tr:last-child {
  border-bottom: 1px solid #eaecf0 !important;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

#customers tr:first-child {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

/* #customers tr:hover {background-color: #ddd;} */

#customers .th {
  text-align: left;
  background-color: #fff;
}

.table-container {
  overflow-x: auto;
}


