.collapse-button {
    display: block;
  
  }
  
  .collapse-content.collapsed {
    display: none;
  }
  
  .collapsed-content.expanded {
    display: block;
  }